import React from "react";
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
const Breadcrumbs = ({links}) => {

    return (
        <section id="breadcrumbs" className="breadcrumbs">
            <div className="container">
                <ol>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    {
                        links.map((item , index)=>(
                            <li key={index}>
                                {item.link ? <Link to={item.link} >{item.label}</Link> : item.label}

                            </li>
                        ))
                    }
                </ol>
            </div>
        </section>
    );
}

Breadcrumbs.propTypes = {
    links: PropTypes.array
};

export default Breadcrumbs;