import React, {useEffect} from "react";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import ContactForm from "../../Components/ContactForm/ContactForm";

const Contact = () => {
    const links = [
        {label: 'Contact'}
    ];

    useEffect(() => {
        document.title = 'GLMD - Contact';
    }, []);
    return (<>
            <Breadcrumbs links={links}/>
            <section id="contact" className="inner-page contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Contact</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 d-flex">
                            <div className="info">
                                <div className="phone">
                                    <i className="bi bi-phone"></i>
                                    <h4>Phone:</h4>
                                    <p>
                                        +31 619 212 613
                                    </p>
                                </div>
                                <div className="phone">
                                    <i className="bi bi-telegram"></i>
                                    <h4>Telegram:</h4>
                                    <p>
                                        +31 619 212 613
                                    </p>
                                </div>
                                <div className="phone">
                                    <i className="bi bi-whatsapp"></i>
                                    <h4>WhatsApp:</h4>
                                    <p>
                                        +31 619 212 613
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex">
                            <div className="info">
                                <div className="email">
                                    <i className="bi bi-envelope"></i>
                                    <h4>Email:</h4>
                                    <p>info@glmd.nl</p>
                                </div>
                                <div className="address">
                                    <i className="bi bi-geo-alt"></i>
                                    <h4>Location:</h4>
                                    <p>Boekweitstraat 23, 2153 GK Nieuw-Vennep, the Netherlands </p>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </section>
        </>
    );
}

export default Contact;