import React from "react";

import {Outlet} from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Main() {
    return (
        <>
            <Header/>
            <main id="main">
                <Outlet/>
            </main>
            <Footer/>
        </>
    );
}

export default Main;