import {React, useRef, useState} from "react";
import {HttClient} from "../../Helpers/HttClient";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import Reaptcha from 'reaptcha';


function ContactForm() {
    const [formData, setFormData] = useState({});
    const [captchVerified, setCaptchVerified] = useState(false);
    const form = useRef();
    const [loading, setLoading] = useState(false);
    const [succeed, setSucceed] = useState(false);
    const [error, setError] = useState(null);


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSuccessSubscribed = (response) => {
        NotificationManager.success(response.msg);
        setLoading(false);
        setSucceed(true);
        setTimeout(() => {
            setFormData({});
            form.current.reset();
        }, 1000);


    }

    const handleError = (response) => {
        setLoading(false);
        setError(response.msg);
        NotificationManager.error(response.msg);
    }

    const handleSubmit = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const client = new HttClient();
        setLoading(true);
        client.post('api/messages', formData, handleSuccessSubscribed, handleError);

    }

    const [captchaToken, setCaptchaToken] = useState(null);
    const captchaRef = useRef(null);

    const verify = () => {
        captchaRef.current.getResponse().then(res => {
            setCaptchaToken(res)
            setCaptchVerified(true);
        })

    }

    return (<form action="" method="post" role="form" className="php-email-form" ref={form} onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-md-6 form-group">
                <input type="text" name="name" className="form-control" id="name"
                       placeholder="نام" required="" onChange={handleInputChange}/>
            </div>
            <div className="col-md-6 form-group mt-3 mt-md-0">
                <input type="text" className="form-control" name="subject" id="subject"
                       placeholder="موضوع" required="" onChange={handleInputChange}/>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-md-6 form-group">
                <input type="text" name="contact_number" className="form-control" id="contact_number"
                       placeholder="تلفن تماس" required="" onChange={handleInputChange}/>
            </div>
            <div className="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" className="form-control" name="email" id="email" onChange={handleInputChange}
                       placeholder="ایمیل" required=""/>
            </div>
        </div>

        <div className="form-group mt-3">
                                <textarea className="form-control" name="body" rows="5" placeholder="متن پیام"
                                          required="" onChange={handleInputChange}></textarea>
        </div>
        <div className='row '>
            <div className='col-md-6 col-xs-12 mt-3'>
                <Reaptcha
                    sitekey="6Lfqc4cjAAAAAE8Kh74XYMWkICVgLuPC-alPFkgS"
                    ref={captchaRef}
                    onVerify={verify}
                />
            </div>
            <div className='col-md-6 col-xs-12'>
                <div className="text-center mt-3">
                    <button type="submit" disabled={!captchVerified}>

                        ارسال پیام
                        {loading && <LoadingSpinner/>}
                    </button>
                </div>
            </div>
        </div>

    </form>);
}

export default ContactForm;