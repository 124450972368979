import React, {useEffect} from "react";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";

const Service = () => {

    const links = [
        {label: 'Services'}
    ];

    useEffect(() => {
        document.title = 'GLMD - Services';
    }, []);

    return (<>
        <Breadcrumbs links={links}/>
        <section className="inner-page">
            <div className="container" data-aos="fade-up" data-aos-delay="200">
                <div className="section-title">
                    <h2>Services</h2>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="d-flex justify-content-center">
                            <img src="/assets/img/brands.jpg" className="img-fluid d-flex justify-content-center"/>
                        </div>
                        <p className="mt-3">
                            Thanks to a broad network of suppliers GLMD can provide its clients with products and
                            instruments – at a competitive price level, within primarily the following product
                            categories:

                        </p>
                        <ul className="mt-3">
                            <li>
                                Life science
                            </li>
                            <li>
                                Molecular biology
                            </li>
                            <li>
                                Reagents and consumables
                            </li>
                        </ul>
                        <p className="text-info">Please consult us for other requests!</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex justify-content-center">
                            <img src="/assets/img/logistics.jpg" className="img-fluid d-flex justify-content-center" />
                        </div>

                        <p className="mt-3">
                            GLMD purchases and stores products and instruments from various suppliers, before shipping to
                            its clients. The advantage of this service is the reduction of the transportation cost, as
                            everything is shipped together.</p>
                        <p>GLMD has the ability to keep the transportation cost to the minimum thanks to the excellent
                            logistics network of the company.</p>

                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Service;