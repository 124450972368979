import React from "react";
import {Link} from "react-router-dom";
import NewsletterSubscriptionForm from "../../Components/NewsletterSubscriptionForm/NewsletterSubscriptionForm";

function Footer() {
    return (<footer id="footer">
            <div className="container footer-bottom clearfix">
                <div className="copyright">
                    &copy; 2023 Copyright <strong><span>GLMD</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
);
}

export default Footer;