import React, {useEffect} from "react";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";

const About = () => {

    const links = [
        {label: 'About'}
    ];

    useEffect(() => {
        document.title = 'GLMD - About';
    }, []);

    return (<>
    <Breadcrumbs links={links}/>
    <section className="inner-page">
        <div className="container" data-aos="fade-up" data-aos-delay="200">
            <div className="section-title">
                <h2>About</h2>
            </div>
            <div className="row" >
                <div className="col-md-12">
                    <p>
                        GLMD was established by professionals who have years of experience of procurement in
                        the life science field, and logistics.
                    </p>
                    <p>
                        GLMD strives to be the preferred partner of its clients, by a customer-oriented approach defined
                        by:
                    </p>
                    <ul className="mt-3">
                        <li>Rapid response to the requests of our clients</li>
                        <li>Reliable and timely communication</li>
                        <li>Assisting the business of our clients by going the extra mile for keeping the procurement- and
                            transportation costs to a minimum</li>
                    </ul>


            </div>
        </div>
    </div>
    </section>
</>)
    ;
}

export default About;