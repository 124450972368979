import React from "react";
import NavBar from "../../Components/NavBar/NavBar";

function Header() {
    return (
        <header id="header" className="header-inner-pages">
            <div className="container d-flex align-items-center">

                <h1 className="logo me-auto">
                    <a href="/">
                        <span className="text-orange">G</span>
                        <span className="text-blue">L</span>
                        <span className='text-red'>M</span>
                        <span className='text-green'>D</span>
                    </a>
                </h1>
                <NavBar/>
            </div>
        </header>);
}

export default Header;