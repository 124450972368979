import React, {useEffect} from "react";
import {Link} from "react-router-dom";

const Home = () => {

    useEffect(() => {
        document.title = 'GLMD - life science/molecular biology/laboratory products';
    }, []);

    return (
        <>
            <section id="hero" className="d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                            data-aos="fade-up" data-aos-delay="200">
                            <h1>GLMD</h1>
                            <p>
                                We are a professional team, eager to support our clients with the supply of life
                                science/molecular
                                biology/laboratory products from all the well-known brands
                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>Services</h3>
                                    <p>We go the extra mile to assist our clients. <Link to="services">
                                            Read more ...
                                        </Link>
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <h3>About</h3>
                                    <p>Let us introduce ourselves.
                                    <br/>
                                        <Link to="about">Read more ... </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                            <img src="/assets/img/slide.jpg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Home;