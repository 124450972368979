import React, {useRef} from "react";
import './NavBar.css'
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom'
import { isMobile } from "react-device-detect";


const NavBar = () => {
    let menuItems = [
        {link: '', name: 'Home'},
        {link: 'services', name: 'Services'},
        {link: 'about', name: 'About'},
        {link: 'contact', name: 'Contact'},
        ];
    const navBar = useRef();
    const navBarToggleButton = useRef();
    const location = useLocation();
    for (let i in menuItems) {
        const menuItem = menuItems[i];
        if (menuItem.link != '') {
            if (location.pathname.includes(menuItem.link)) {
                menuItem.active = true;
            } else {
                menuItem.active = false;
            }
        } else {
            if (location.pathname == '') {
                menuItem.active = true;
            } else {
                menuItem.active = false;
            }
        }

    }

    const handleNavBarClick = (event) => {
        navBar.current.classList.toggle('navbar-mobile')
        navBarToggleButton.current.classList.toggle('bi-list')
        navBarToggleButton.current.classList.toggle('bi-x')
    }

    return (<nav id="navbar" className="navbar" ref={navBar}>
        <ul>
            {
                menuItems.map((item, index) => (
                        <li key={index} onClick={isMobile ? handleNavBarClick : null}>
                            <Link to={item.link}
                                  className={item.active ? 'nav-link active' : 'nav-link'}>{item.name}</Link>
                        </li>
                    )
                )}
        </ul>
        <i className="bi bi-list mobile-nav-toggle" ref={navBarToggleButton} onClick={handleNavBarClick}></i>
    </nav>);
}


export default NavBar